import React from 'react';

const LegalTerms = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.9301 41.8408H7.84732C7.72425 41.8361 7.60752 41.785 7.52043 41.6979C7.43335 41.6108 7.38233 41.4942 7.37769 41.3711V13.3058C7.37853 13.2422 7.39233 13.1795 7.41816 13.1214C7.44399 13.0633 7.48136 13.011 7.528 12.9678L13.5205 6.97515C13.6074 6.89388 13.7209 6.84723 13.8398 6.84375H31.9301C32.0546 6.84375 32.1741 6.89304 32.2621 6.98111C32.3502 7.06918 32.3997 7.18883 32.3997 7.31338V19.8054C32.3997 19.93 32.3502 20.0496 32.2621 20.1377C32.1741 20.2258 32.0546 20.2751 31.9301 20.2751C31.8055 20.2751 31.6861 20.2258 31.598 20.1377C31.51 20.0496 31.4605 19.93 31.4605 19.8054V7.78301H14.0464L8.41085 13.4186V40.8263H31.5544V28.9539C31.5544 28.8293 31.6039 28.7099 31.6919 28.6218C31.78 28.5337 31.8994 28.4842 32.024 28.4842C32.1485 28.4842 32.2681 28.5337 32.3561 28.6218C32.4442 28.7099 32.4936 28.8293 32.4936 28.9539V41.2959C32.5079 41.3723 32.503 41.451 32.4794 41.525C32.4558 41.599 32.4143 41.6659 32.3584 41.7199C32.3026 41.7739 32.2343 41.8133 32.1595 41.8343C32.0848 41.8554 32.0059 41.8576 31.9301 41.8408Z"
      fill="#475467"
    />
    <path
      d="M7.90377 13.7713C7.78555 13.7646 7.67295 13.7184 7.58434 13.6399C7.53889 13.5961 7.50275 13.5434 7.47805 13.4853C7.45336 13.4272 7.44067 13.3648 7.44067 13.3017C7.44067 13.2385 7.45336 13.1761 7.47805 13.118C7.50275 13.0599 7.53889 13.0075 7.58434 12.9636L13.5769 6.97102C13.6668 6.88918 13.7841 6.84375 13.9057 6.84375C14.0273 6.84375 14.1445 6.88918 14.2344 6.97102C14.2799 7.01483 14.316 7.06748 14.3407 7.12558C14.3654 7.18367 14.3781 7.24613 14.3781 7.30925C14.3781 7.37238 14.3654 7.43484 14.3407 7.49293C14.316 7.55103 14.2799 7.60345 14.2344 7.64726L8.24189 13.6399C8.14752 13.7211 8.0282 13.7674 7.90377 13.7713Z"
      fill="#475467"
    />
    <path
      d="M13.8962 13.7754H7.90371C7.77916 13.7754 7.65963 13.7259 7.57155 13.6378C7.48348 13.5498 7.43408 13.4303 7.43408 13.3058C7.43873 13.1827 7.48963 13.0659 7.57671 12.9788C7.6638 12.8917 7.78064 12.8408 7.90371 12.8362H13.4266V7.31338C13.4266 7.18883 13.476 7.06918 13.5641 6.98111C13.6521 6.89304 13.7717 6.84375 13.8962 6.84375C14.0208 6.84375 14.1402 6.89304 14.2283 6.98111C14.3163 7.06918 14.3659 7.18883 14.3659 7.31338V13.3058C14.3685 13.3682 14.3581 13.4304 14.3355 13.4885C14.3128 13.5467 14.2783 13.5996 14.2341 13.6438C14.19 13.6879 14.1372 13.7225 14.079 13.7451C14.0208 13.7678 13.9586 13.7781 13.8962 13.7754Z"
      fill="#475467"
    />
    <path
      d="M24.9797 32.0681C24.9201 32.0705 24.8608 32.0601 24.8056 32.0374C24.7505 32.0147 24.701 31.9804 24.6604 31.9367C24.6024 31.8826 24.5597 31.8142 24.5367 31.7384C24.5136 31.6625 24.5108 31.5819 24.5289 31.5047L25.0549 28.8183C25.072 28.7251 25.1179 28.6397 25.1863 28.5741L34.5789 19.1814C34.6222 19.1348 34.6745 19.0974 34.7326 19.0716C34.7907 19.0458 34.8536 19.0321 34.9172 19.0312C34.978 19.0324 35.0378 19.0464 35.0928 19.0723C35.1479 19.0982 35.1968 19.1353 35.2365 19.1814L37.4157 21.3418C37.4611 21.3856 37.4971 21.438 37.5218 21.4961C37.5465 21.5542 37.5592 21.6167 37.5592 21.6798C37.5592 21.7429 37.5465 21.8056 37.5218 21.8637C37.4971 21.9218 37.4611 21.9742 37.4157 22.018L27.929 31.4107C27.8662 31.4826 27.7794 31.5293 27.6848 31.5421L24.9985 32.0681H24.9797ZM25.9378 29.1939L25.5808 31.0725L27.4594 30.6968L36.5138 21.6612L35.0111 20.1583L25.9378 29.1939Z"
      fill="#475467"
    />
    <path
      d="M38.2421 21.0807C38.1167 21.0819 37.9957 21.0349 37.9041 20.9493L35.7437 18.7701C35.6988 18.728 35.6629 18.6769 35.6385 18.6204C35.614 18.5638 35.6013 18.5029 35.6013 18.4413C35.6013 18.3797 35.614 18.3187 35.6385 18.2622C35.6629 18.2057 35.6988 18.1549 35.7437 18.1127L37.021 16.8352C37.0648 16.7897 37.1175 16.7537 37.1756 16.729C37.2336 16.7043 37.2961 16.6914 37.3592 16.6914C37.4224 16.6914 37.4848 16.7043 37.5429 16.729C37.601 16.7537 37.6534 16.7897 37.6972 16.8352L39.8576 18.9955C39.9025 19.0377 39.9384 19.0885 39.9628 19.1451C39.9873 19.2016 40 19.2625 40 19.3241C40 19.3858 39.9873 19.4469 39.9628 19.5035C39.9384 19.56 39.9025 19.6108 39.8576 19.653L38.5803 20.9493C38.4887 21.0349 38.3675 21.0819 38.2421 21.0807ZM36.7394 18.4507L38.2421 19.9536L38.8619 19.3335L37.3592 17.8309L36.7394 18.4507Z"
      fill="#475467"
    />
    <path
      d="M32.1555 24.8385C32.03 24.8397 31.909 24.7927 31.8173 24.7071C31.7355 24.6172 31.6902 24.4999 31.6902 24.3783C31.6902 24.2567 31.7355 24.1394 31.8173 24.0494L35.7435 20.1234C35.8334 20.0415 35.9505 19.9961 36.0721 19.9961C36.1937 19.9961 36.311 20.0415 36.401 20.1234C36.4464 20.1672 36.4827 20.2198 36.5074 20.2779C36.5321 20.336 36.5447 20.3985 36.5447 20.4616C36.5447 20.5247 36.5321 20.5872 36.5074 20.6453C36.4827 20.7034 36.4464 20.7558 36.401 20.7996L32.4936 24.7071C32.4494 24.7512 32.3965 24.7856 32.3383 24.8082C32.2801 24.8309 32.2179 24.8412 32.1555 24.8385Z"
      fill="#475467"
    />
    <path
      d="M27.6846 31.5445C27.6222 31.5472 27.56 31.5371 27.5017 31.5144C27.4435 31.4918 27.3907 31.4572 27.3465 31.4131L25.1861 29.2527C25.1407 29.2089 25.1046 29.1565 25.0799 29.0984C25.0552 29.0403 25.0425 28.9779 25.0425 28.9147C25.0425 28.8516 25.0552 28.7891 25.0799 28.731C25.1046 28.6729 25.1407 28.6203 25.1861 28.5765C25.2761 28.4946 25.3934 28.4492 25.515 28.4492C25.6366 28.4492 25.7538 28.4946 25.8437 28.5765L28.0039 30.7556C28.0489 30.7978 28.0847 30.8486 28.1092 30.9052C28.1337 30.9617 28.1463 31.0226 28.1463 31.0843C28.1463 31.1459 28.1337 31.2068 28.1092 31.2633C28.0847 31.3199 28.0489 31.3709 28.0039 31.4131C27.9626 31.4558 27.9129 31.4895 27.858 31.5121C27.803 31.5348 27.744 31.5457 27.6846 31.5445Z"
      fill="#475467"
    />
    <path
      d="M28.3422 18.4705H11.5668C11.4422 18.4705 11.3228 18.421 11.2348 18.3329C11.1467 18.2449 11.0972 18.1254 11.0972 18.0009C11.0972 17.8763 11.1467 17.7569 11.2348 17.6688C11.3228 17.5808 11.4422 17.5312 11.5668 17.5312H28.3422C28.4667 17.5312 28.5861 17.5808 28.6742 17.6688C28.7623 17.7569 28.8118 17.8763 28.8118 18.0009C28.8118 18.1254 28.7623 18.2449 28.6742 18.3329C28.5861 18.421 28.4667 18.4705 28.3422 18.4705Z"
      fill="#475467"
    />
    <path
      d="M28.3422 22.6932H11.5668C11.4422 22.6932 11.3228 22.6437 11.2348 22.5556C11.1467 22.4675 11.0972 22.3481 11.0972 22.2235C11.0972 22.099 11.1467 21.9796 11.2348 21.8915C11.3228 21.8034 11.4422 21.7539 11.5668 21.7539H28.3422C28.4667 21.7539 28.5861 21.8034 28.6742 21.8915C28.7623 21.9796 28.8118 22.099 28.8118 22.2235C28.8118 22.3481 28.7623 22.4675 28.6742 22.5556C28.5861 22.6437 28.4667 22.6932 28.3422 22.6932Z"
      fill="#475467"
    />
    <path
      d="M24.5851 26.9197H11.5668C11.4422 26.9197 11.3228 26.8702 11.2348 26.7821C11.1467 26.6941 11.0972 26.5747 11.0972 26.4501C11.0972 26.3255 11.1467 26.2061 11.2348 26.1181C11.3228 26.03 11.4422 25.9805 11.5668 25.9805H24.5851C24.7097 25.9805 24.8291 26.03 24.9172 26.1181C25.0052 26.2061 25.0547 26.3255 25.0547 26.4501C25.0547 26.5747 25.0052 26.6941 24.9172 26.7821C24.8291 26.8702 24.7097 26.9197 24.5851 26.9197Z"
      fill="#475467"
    />
    <path
      d="M22.7066 31.1463H11.5668C11.4422 31.1463 11.3228 31.0968 11.2348 31.0087C11.1467 30.9206 11.0972 30.8012 11.0972 30.6767C11.0972 30.5521 11.1467 30.4327 11.2348 30.3446C11.3228 30.2565 11.4422 30.207 11.5668 30.207H22.7066C22.8311 30.207 22.9506 30.2565 23.0386 30.3446C23.1267 30.4327 23.1762 30.5521 23.1762 30.6767C23.1762 30.8012 23.1267 30.9206 23.0386 31.0087C22.9506 31.0968 22.8311 31.1463 22.7066 31.1463Z"
      fill="#475467"
    />
    <path
      d="M28.3422 35.3572H11.5668C11.4422 35.3572 11.3228 35.3077 11.2348 35.2196C11.1467 35.1316 11.0972 35.0122 11.0972 34.8876C11.0972 34.763 11.1467 34.6436 11.2348 34.5556C11.3228 34.4675 11.4422 34.418 11.5668 34.418H28.3422C28.4667 34.418 28.5861 34.4675 28.6742 34.5556C28.7623 34.6436 28.8118 34.763 28.8118 34.8876C28.8118 35.0122 28.7623 35.1316 28.6742 35.2196C28.5861 35.3077 28.4667 35.3572 28.3422 35.3572Z"
      fill="#475467"
    />
  </svg>
);

export default LegalTerms;
